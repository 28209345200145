import { createContext, useReducer } from 'react';

export const ApplyContext = createContext(null);
// Define action types
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_DEGREE = 'SET_DEGREE';
export const SET_PREFERRED_COUNTRY = 'SET_PREFERRED_COUNTRY';
export const SET_PREFERRED_STATE = 'SET_PREFERRED_STATE';
export const SET_FUNDING_OPTION = 'SET_FUNDING_OPTION';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_DOB = 'SET_DOB';
export const SET_NUMBER = 'SET_NUMBER';
export const SET_PLAN = 'SET_PLAN';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_POPUP = 'SET_POPUP';
export const SET_POPUP_MESSAGE = 'SET_POPUP_MESSAGE';
export const SET_COURSES = 'SET_COURSES';

const ApplyProvider = ({ children }) => {
	const initialState = {
		category: [],
		degree: '',
		preferred_location: {
			country: '',
			state: '',
		},
		fundingOption: '',
		firstName: '',
		lastName: '',
		email: '',
		country: '',
		dob: '',
		phone: '',
		plan: 'free',
		username: '',
		password: '',
		search: '',
		successPopupStatus: null,
		successPopupMessage: '',
		courses: [],
	};
	// const initialState = {
	// 	category: ['64c36a77cb14aafcb610e06f'],
	// 	degree: 'bachelor',
	// 	preferred_location: {
	// 		country: 'IN',
	// 		state: 'Delhi',
	// 	},
	// 	fundingOption: 'My parents and family will be funding my education',
	// 	firstName: 'Antar',
	// 	lastName: 'Das',
	// 	email: 'antardaspeacockindia@gmail.com',
	// 	country: 'BD',
	// 	dob: 'Fri Sep 01 2000 00:00:00 GMT+0600 (Bangladesh Standard Time)',
	// 	phone: '1844850152',
	// 	plan: 'paid',
	// 	username: 'antar-das-1',
	// 	password: 'hard-password-1',
	// };
	// localStorage.setItem(
	// 	'applyItem',
	// 	JSON.stringify({
	// 		category: ["64b77499c523030a3017ba20"],
	// 		degree: 'bachelor',
	// 		preferred_location: '{country: "Algeria", state: "AX"}',
	// 		fundingOption: 'I will be sponsored by my government or company',
	// 		firstName: 'Antar',
	// 		lastName: 'Das',
	// 		email: 'antardaspeacockindia@gmail.com',
	// 		country: 'BD',
	// 		dob: 'Sun Jun 25 2023 11:16:01 GMT+0600 (Bangladesh Standard Time)',
	// 		phone: '01844850152',
	// 		plan: 'paid',
	// 	})
	// );

	// const initialState = JSON.parse(localStorage.getItem('applyItem'));

	const reducer = (state, action) => {
		switch (action.type) {
			case SET_CATEGORY:
				return {
					...state,
					category: [action.payload],
				};
			case SET_DEGREE:
				return { ...state, degree: action.payload };
			case SET_PREFERRED_COUNTRY:
				return {
					...state,
					preferred_location: {
						...state.preferred_location,
						country: action.payload,
					},
				};
			case SET_PREFERRED_STATE:
				return {
					...state,
					preferred_location: {
						...state.preferred_location,
						state: action.payload,
					},
				};
			case SET_FUNDING_OPTION:
				return { ...state, fundingOption: action.payload };
			case SET_FIRST_NAME:
				return { ...state, firstName: action.payload };
			case SET_LAST_NAME:
				return { ...state, lastName: action.payload };
			case SET_EMAIL:
				return { ...state, email: `${action.payload}`.toLowerCase() };
			case SET_COUNTRY:
				return { ...state, country: action.payload };
			case SET_DOB:
				return { ...state, dob: action.payload };
			case SET_NUMBER:
				return { ...state, phone: action.payload };
			case SET_PLAN:
				return { ...state, plan: action.payload };
			case SET_USERNAME:
				return { ...state, username: action.payload };
			case SET_PASSWORD:
				return { ...state, password: action.payload };
			case SET_SEARCH:
				return { ...state, search: action.payload };
			case SET_POPUP:
				return { ...state, successPopupStatus: action.payload };
			case SET_POPUP_MESSAGE:
				return { ...state, successPopupMessage: action.payload };
			case SET_COURSES:
				return { ...state, courses: action.payload };
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	return <ApplyContext.Provider value={{ state, dispatch }}>{children}</ApplyContext.Provider>;
};

export default ApplyProvider;
/*  */
