import React from "react";
import { ScaleLoader } from "react-spinners";

const Spinner = () => {
  return (
    <div className="fixed left-0 top-0 w-full h-full flex justify-center items-center bg-[#0000001c]">
      <ScaleLoader size={200} className="w-[100px]" color="#0094D6" />
    </div>
  );
};

export default Spinner;
