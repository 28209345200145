import { useContext } from 'react';
import { ApplyContext } from '../context/ApplyProvider';
/**
 * @typedef {Object} InitialStateType
 * @property {Array<string>} category The category property.
 * @property {string} degree The degree property.
 * @property {Object} preferred_location The preferred_location property.
 * @property {string} preferred_location.country The preferred_location country property.
 * @property {string} preferred_location.state The preferred_location state property.
 * @property {string} fundingOption The fundingOption property.
 * @property {string} firstName The firstName property.
 * @property {string} lastName The lastName property.
 * @property {string} email The email property.
 * @property {string} country The country property.
 * @property {string} dob The dob property.
 * @property {Object} number The number property.
 * @property {string} number.code The number code property.
 * @property {string} number.number The number number property.
 * @property {string} plan The plan property.
 * @property {string} search The search property.
 * @property {string} successPopupStatus The search property.
 * @property {string} successPopupMessage
 * @property {string} courses
 */

/**
 * Custom hook to access the application context provided by ApplyProvider.
 * @returns {{
 *   state: InitialStateType,
 *   dispatch: (action: { type: string, payload: any }) => void
 * }}
 * An object containing the application state and dispatch function.
 * @throws {Error} Throws an error if used outside the ApplyProvider.
 */
const useApplyContext = () => {
	const context = useContext(ApplyContext);
	if (!context) {
		throw new Error('useApplyContext must be used within an ApplyProvider');
	}
	return context;
};

export default useApplyContext;
