import React, { useEffect, useState } from "react";
import { BeatLoader, MoonLoader } from "react-spinners";
import { Button } from "reactstrap";

const Buttons = ({
  fill,
  data,
  className,
  onClick,
  type,
  disable,
  processing,
  processingMessage,
}) => {
  return (
    <>
      {fill ? (
        <Button
          disabled={disable || processing && true}
          type={type}
          onClick={onClick}
          className={`${className} ${
            disable &&
            "brightness-75 cursor-not-allowed !bg-primary_ hover:!text-white_ !border-none"
          } font-Cairo py-3 ${processing && 'hover:!bg-primary_ hover:!text-white_'} px-8 border-[3px] border-primary_ rounded-[50px] font-semibold hover:bg-white_ hover:text-primary_ bg-primary_ text-white_ transition-all duration-300`}
        >
          {processing ? (
            <div className="flex items-center">
              <MoonLoader size={10} color="#ffffff" />
              <p className="ml-2">{processingMessage}</p>
            </div>
          ) : (
            data
          )}
        </Button>
      ) : (
        <Button
          disabled={disable || processing && true}
          type={type}
          onClick={onClick}
          className={`font-Cairo py-3 ${processing && ' hover:!bg-white_ hover:text-primary_ '} px-14 border-[3px] border-primary_ rounded-[50px] font-semibold text-primary_ hover:bg-primary_ hover:text-white_ transition-all duration-300 ${className}`}
        >
          {processing ? (
            <div className="flex items-center">
              <MoonLoader size={20}  color="#0094D6" />
              <p className="ml-3">{processingMessage}</p>
            </div>
          ) : (
            data
          )}
        </Button>
      )}
    </>
  );
};

export default Buttons;
