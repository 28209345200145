import Spinner from '../components/Spinner';
import { PrivateAPI } from '../utils/axios';
import { useEffect, createContext, useState } from 'react';

export const AuthContext = createContext(null);
const initialUser = {
	_id: null,
	name: null,
	email: null,
	profile_picture: '',
	authType: null,
	interesting_category: [],
	degree_name: '',
	preferred_location: {
		country: null,
		state: null,
	},
	education_fund: null,
	phone: {
		code: null,
		number: null,
	},
	gender: null,
	address: {
		lines: [],
		state: null,
		city: null,
		country: null,
		zip_code: null,
	},
	createdAt: null,
	__v: 0,
	total_saved_course: 0,
	total_application_applied: 0,
	accepted_application: 0,
	rejected_application: 0,
	educations: [],
	experiences: [],
	test_score: [],
	preferences: [],
};
const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(initialUser);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const getUser = async () => {
		setIsLoading(true);
		try {
			const { data } = await PrivateAPI.get('/me');
			setIsLoading(false);
			// console.log(data);
			// debugger;
			setUser(data.data);
		} catch (error) {
			setIsLoading(false);
			setError(error);
		}
	};

	useEffect(() => {
		(async () => {
			await getUser();
		})();
	}, []);

	if (isLoading) {
		return <Spinner />
	}

	const setInitialUser = () => {
		setUser(initialUser)
	}

	return (
		<AuthContext.Provider
			value={{ user, isLoading, setIsLoading, setError, error , setUser, setInitialUser}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
