import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import success from "../../images/icons/Check.svg";
import { useState } from "react";
import Buttons from "../Button";
import { useNavigate } from "react-router-dom";
import useApplyContext from "../../hooks/useApplyContext";
import { SET_POPUP, SET_POPUP_MESSAGE } from "../../context/ApplyProvider";

const Success = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const { state, dispatch } = useApplyContext();

  useEffect(() => {
    if(state?.successPopupStatus !== null) {
      onOpen();
      setMessage(state?.successPopupMessage);
    }
  }, [state]);

  return (
    <>
      <Modal className="z-50 p-6" isOpen={isOpen} onClose={() => {
        onClose()
        dispatch({
          type: SET_POPUP,
          payload: null,
        });
        dispatch({
          type: SET_POPUP_MESSAGE,
          payload: "",
        });
      }}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <img
                  className="w-[100px] h-[100px] block mx-auto"
                  src={success}
                  alt=""
                />
              </ModalHeader>
              <ModalBody>
                {message === "Please check your inbox to activate it." ? (
                  <h2 className="font-Lora text-[25px] font-bold text-center m-0">
                    Account created successfully!
                  </h2>
                ) : (
                  <h2 className="font-Lora text-[25px] font-bold text-center m-0">
                    Success!
                  </h2>
                )}
                <p className="text-center">{message}</p>
              </ModalBody>
              <ModalFooter>
                {message === "Please check your inbox to activate it." ? (
                  <Buttons
                    data={"Continue"}
                    fill={true}
                    onClick={() => {
                      navigate("/admin/dashboard");
                      onClose();
                    }}
                    className={"block mx-auto !w-full"}
                  />
                ) : (
                  <Buttons
                    data={"Continue"}
                    fill={true}
                    onClick={() => {
                      onClose()
                      dispatch({
                        type: SET_POPUP,
                        payload: null,
                      });
                      dispatch({
                        type: SET_POPUP_MESSAGE,
                        payload: "",
                      });
                    }}
                    className={"block mx-auto !w-full"}
                  />
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Success;
