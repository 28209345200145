import axios from 'axios';
import { isProduction } from './helpers';

// const BASE_URL = 'https://glofi.up.railway.app/api/v1';
const BASE_URL = isProduction
	? 'https://glofi-server.peacockindia.in/api/v1'
	: 'http://localhost:4000/api/v1';
	// : 'https://glofi.up.railway.app/api/v1'

const PrivateAPI = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});
let AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN');
PrivateAPI.defaults.headers.common['Authorization'] = AUTH_TOKEN;

const PublicAPI = axios.create({
	baseURL: BASE_URL,
});

const ExtraSourceAPI = axios.create({
	baseURL: 'https://search.prtl.co',
});

export { PrivateAPI, PublicAPI, BASE_URL, ExtraSourceAPI };
