import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import ApplyProvider from "./context/ApplyProvider";
import { Suspense, lazy } from "react";
import Spinner from "./components/Spinner";
import { NextUIProvider } from "@nextui-org/react";
import Success from "./components/Modals/Message";

function App() {
  // ExtraSourceAPI.get('/2023-02-23/?q=en-179|di-54|ci-30|lv-bachelor|tc-EUR|uc-108&size=30&start=0').then((data) => {
  // 	console.log(data.data);
  // });
	const Components = lazy(() => import("../src/router"));
	console.log(process.env.NODE_ENV);
  return (
    <Suspense fallback={<Spinner />}>
      <NextUIProvider>
        <AuthProvider>
          <ApplyProvider>
            <BrowserRouter>
              <Components />
              <Toaster />
              <Success />
            </BrowserRouter>
          </ApplyProvider>
        </AuthProvider>
      </NextUIProvider>
    </Suspense>
  );
}

export default App;
